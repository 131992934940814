import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { history } from '../..'
import ValidationSummary from '../../components/ValidationSummary'
import VehicleBuilder from '../../components/VehicleBuilder'
import { ConfusedWrapper } from '../../components/common/ConfusedWrapper'
import { ErrorMessage } from '../../components/common/ErrorMessage'
import { FieldLabel } from '../../components/common/FieldLabel'
import { SectionHeading } from '../../components/common/SectionHeading'
import { VEHICLE_OPTIONS } from '../../services/constants'
import { HOME_PAGE_REDESIGNED_VALIDATION } from '../../services/validation'
import { isLoading, updateStep, requestConfusedExternalAccount } from '../App/actions'
import RiskDataDiscountBannerContainer from '../RiskDataCollectionPage/components/RiskDataDiscountBannerContainer.component'
import { createUnkownVehicle, requestVehicle } from '../Vehicle/actions'
import {
  COMMERCIAL_VEHICLE,
  COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT,
  PRIVATE_CAR,
} from '../Vehicle/constants'
import {
  GBIcon,
  GetQuoteWrapper,
  RegInput,
  RegWrapper,
  TeslaQuoteNextButton,
  VehicleBuilderText,
  VehicleBuilderTextContainer,
} from './styles'
import Spinner from '../../components/Spinner'
import { SYSTEM_FEATURES } from '../../services/constants'
import { SummaryWrapper } from '../../components/MiniSummary/MiniSummary.styles'
import { Button } from '../../components/common/Button'
import queryString from 'query-string'
import { useAuth } from 'react-oidc-context';
import { BannerText, BannerWrapper, BannerGrid } from '../../components/Discountbanner/DiscountBanner.styles'
import { SemiBold } from '../../components/StaticPages/styles' 

const HomePage = ({ vehicle, appStartupComplete, actions, features, confusedExternalAccount }) => {
  const [vehicleSearched, setVehicleSearched] = useState(false);
  const [userHasVehicles, setUserHasVehicles] = useState(false);
  const [searchingRegNumber, setSearchingRegNumber] = useState(false);
  const [showLoginBanner, setShowLoginBanner] = useState(false);

  const auth = useAuth()

  useEffect(() => {
    actions.updateStep(0)
  }, [])

  const isInternalRvuUser = () => {
    const parsedQueryString = queryString.parse(window.location.search)
    return parsedQueryString.InternalRvuUserOverride === 'true'
  }

  useEffect(() => {
    if (appStartupComplete) {
      if (features[SYSTEM_FEATURES.CONFUSED_EXTERNAL_ACCOUNT] === true || isInternalRvuUser) {
        const checkIfExistingSession = async () => {   
          if (!auth.isLoading)
          {
            if (!auth.isAuthenticated) {
              setShowLoginBanner(features[SYSTEM_FEATURES.CONFUSED_EXTERNAL_ACCOUNT_SHOW_LOGIN] === true || isInternalRvuUser)
              await auth.signinSilent()
            }
          }

          if (auth.isAuthenticated){
            setShowLoginBanner(false)
            confusedExternalAccount.AuthToken = `Bearer ${auth.user.access_token}`
            actions.requestConfusedExternalAccount(`Bearer ${auth.user.access_token}`)
          }
        };
        
        void checkIfExistingSession();
      }
    }    
  }, [appStartupComplete])

  useEffect(() => {
    if (auth.isAuthenticated){
      setShowLoginBanner(false)
      confusedExternalAccount.AuthToken = `Bearer ${auth.user.access_token}`
      actions.requestConfusedExternalAccount(`Bearer ${auth.user.access_token}`)
    }
  }, [auth.isAuthenticated])

  useEffect(() => {
    if (!isInternalRvuUser && (features[SYSTEM_FEATURES.CONFUSED_EXTERNAL_ACCOUNT] === false || features[SYSTEM_FEATURES.CONFUSED_EXTERNAL_ACCOUNT_VEHICLE] === false))
    {
      setUserHasVehicles(false)
      return;
    }

    const userVehiclesCheck = confusedExternalAccount.Account != null && confusedExternalAccount.Account.Vehicles != null && confusedExternalAccount.Account.Vehicles.length > 0
    setUserHasVehicles(userVehiclesCheck)
  }, [confusedExternalAccount])

  useEffect(() => {
    if (vehicleSearched && vehicle.searched.VehicleId != null)
    {
      let url = `/quote/cover-details?registration=${vehicle.searched.RegistrationNumber}&experiment=de64efbb-1135-4367-9f6e-27a8c802b885`
      const parsedQueryString = queryString.parse(window.location.search)
      if (parsedQueryString.confusedVehicleId){
        url += `&confusedVehicleId=${parsedQueryString.confusedVehicleId}`
      }
      if (parsedQueryString.InternalRvuUserOverride)
      {
        url += `&InternalRvuUserOverride=${parsedQueryString.InternalRvuUserOverride}`
      }
      history.push(url)
    }
  }, [vehicle])

  const urlPrefix = (url) => {
    return (url.endsWith('?')) ? '' : '&'
  }

  const handleConfirm = () => {
    let url = `/quote/cover-details?`

    actions.isLoading(true)
    if (vehicle.searched.Make !== null)
      url = url.concat(`${urlPrefix(url)}make=${vehicle.searched.Make}`)
    if (vehicle.searched.Model !== null)
      url = url.concat(`${urlPrefix(url)}model=${vehicle.searched.Model}`)
    if (vehicle.searched.Abi !== null)
      url = url.concat(`${urlPrefix(url)}variant=${vehicle.searched.Abi}`)
    if (vehicle.searched.GrossWeight !== null)
      url = url.concat(`${urlPrefix(url)}weight=${vehicle.searched.GrossWeight}`)
    if (vehicle.searched.YearOfManufacture !== null)
      url = url.concat(`${urlPrefix(url)}year=${vehicle.searched.YearOfManufacture}`)

    actions.createUnkownVehicle(url)
  }

  const handleStartQuote = (values) => {
    actions.isLoading(true)
    if (values.ConfusedVehicleSelected != null){
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set("confusedVehicleId", values.ConfusedVehicleSelected);
      const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
      history.replace(newRelativePathQuery);
    }

    setSearchingRegNumber(true)
    if (values.ShowVehicleBuilder) {
      handleConfirm(values)
    } else {
      actions.requestVehicle(values.Registration)
      setVehicleSearched(true)
    }
  }

  const onRegInput = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase()
    e.target.value = e.target.value.replace(/\s/g, '')
  }

  return (
    <ConfusedWrapper>
      <RiskDataDiscountBannerContainer></RiskDataDiscountBannerContainer>
      
      {
        showLoginBanner && (
          <BannerWrapper paddingLeft='24px' paddingRight='24px' paddingTop='32px' paddingBottom='32px'>
            <BannerGrid>
              <BannerText>
                <strong>
                  Sign in to your Confused.com account to use details you've already saved for a quicker quote
                </strong>
                <Button 
                  onClick={() => auth.signinRedirect()}
                  marginBottom={'12px'}
                  marginTop={'12px'}
                >
                  Sign in now
                </Button>
              </BannerText>
            </BannerGrid>
          </BannerWrapper>
        )
      }

      <SectionHeading>Temporary vehicle insurance</SectionHeading>
      <section id='MainCTA' className=''>
        <div className='grid-100 grid-parent'>
          <section id='Tesla' className=''>
            <Formik
              initialValues={{
                Registration: vehicle.selected.RegistrationNumber,
                ShowVehicleBuilder: false,
                VehicleType: vehicle.selected.VehicleType,
                VehicleMake: vehicle.selected.Make,
                VehicleModel: vehicle.selected.Model,
                VehicleVariant: vehicle.selected.Abi,
                VehicleWeight: vehicle.selected.GrossWeight,
                VehicleDateOfManufacture: vehicle.selected.YearOfManufacture,
              }}
              validationSchema={HOME_PAGE_REDESIGNED_VALIDATION}
              onSubmit={(values) => handleStartQuote(values)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                setFieldTouched,
                setFieldValue,
                setValues,
                submitCount,
                touched,
                values
              }) => {
                const errorCount = Object.keys(errors || {}).length
                /* eslint-disable react-hooks/rules-of-hooks */
                const [globalSubmitCount, setGlobalSubmitCount] = useState(0)
                if (
                  errorCount > 0 &&
                  submitCount > 0 &&
                  globalSubmitCount !== submitCount
                ) {
                  setFieldTouched('Registration', !values.ShowVehicleBuilder)
                  setFieldTouched('VehicleType', !!values.ShowVehicleBuilder)
                  setFieldTouched(
                    'VehicleMake',
                    (values.VehicleType === COMMERCIAL_VEHICLE &&
                      values.VehicleWeight &&
                      values.VehicleWeight <=
                        COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) ||
                      values.VehicleType === PRIVATE_CAR
                  )
                  setFieldTouched('VehicleModel', !!values.VehicleMake)
                  setFieldTouched('VehicleVariant', !!values.VehicleModel)
                  setFieldTouched(
                    'VehicleWeight',
                    values.VehicleType === COMMERCIAL_VEHICLE
                  )
                  setFieldTouched(
                    'VehicleDateOfManufacture',
                    values.VehicleType === COMMERCIAL_VEHICLE
                  )
                  setGlobalSubmitCount(submitCount)
                }
                return (
                  <Form id='TeslaForm'>
                    <ValidationSummary
                      showSummary={errorCount > 0 && submitCount > 0}
                      submitCount={submitCount}
                    />
                    <div className='select-vehicle'>
                      <section id='FindByReg'>
                        {!values.ShowVehicleBuilder && (
                          <>
                            <FieldLabel>
                              What is the reg of the vehicle you need cover for?
                            </FieldLabel>
                            {touched.Registration && errors.Registration && (
                              <ErrorMessage id='RegistrationError'>
                                {errors.Registration}
                              </ErrorMessage>
                            )}
                            <RegWrapper className='reg-wrapper'>
                              <GBIcon
                                className='gb-icon'
                                error={Boolean(
                                  touched.Registration && errors.Registration
                                )}
                              >
                                GB
                              </GBIcon>
                              <RegInput
                                id='Registration'
                                autoComplete='off'
                                name='Registration'
                                type='text'
                                autoCorrect='off'
                                value={values.Registration != null ? values.Registration : ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onInput={onRegInput}
                                tabIndex={1}
                                error={Boolean(
                                  touched.Registration && errors.Registration
                                )}
                              />
                            </RegWrapper>
                            <VehicleBuilderTextContainer>
                              <VehicleBuilderText
                                onClick={() => {
                                  setFieldValue('Registration', null)
                                  setFieldValue('ShowVehicleBuilder', true)
                                }}
                              >
                                I don't know the reg yet
                              </VehicleBuilderText>
                            </VehicleBuilderTextContainer>
                          </>
                        )}
                        {values.ShowVehicleBuilder && (
                          <>
                            <FieldLabel>
                              Find the vehicle from the options below
                            </FieldLabel>
                            <VehicleBuilder
                              searchedVehicle={vehicle.searched}
                              setFieldValue={setFieldValue}
                              setValues={setValues}
                              values={values}
                              errors={errors}
                              touched={touched}
                              vehicleOptions={{
                                vehicleType: VEHICLE_OPTIONS,
                                makes: vehicle.makes,
                                models: vehicle.models,
                                variants: vehicle.variants,
                              }}
                              handleSelect={handleChange}
                              onBlur={handleBlur}
                            />
                          </>
                        )}
                        {values.ShowVehicleBuilder && (
                          <VehicleBuilderTextContainer>
                            <VehicleBuilderText
                              onClick={() => {
                                setFieldValue('ShowVehicleBuilder', false)
                              }}
                            >
                              I can enter the reg
                            </VehicleBuilderText>
                          </VehicleBuilderTextContainer>
                        )}
                      </section>
                    </div>
                    
                    <GetQuoteWrapper>
                      <TeslaQuoteNextButton
                        id='TeslaQuoteNextButton'
                        type='submit'
                        tabIndex={3}
                        disabled={!appStartupComplete || searchingRegNumber}
                        onMouseDown={(e) => {
                          e.preventDefault()
                        }}
                        includeArrow={appStartupComplete && !searchingRegNumber}
                      >
                        {
                          !appStartupComplete || searchingRegNumber ? 
                            <>
                              Loading...&nbsp;<Spinner />
                            </>
                         :
                            <>
                              Continue
                            </>
                        }
                      </TeslaQuoteNextButton>
                    </GetQuoteWrapper>
                  </Form>
                )
              }}
            </Formik>
          </section>
        </div>
      </section>

      <section>
      {
        userHasVehicles && (
        <>
          <SemiBold>
            Or select one of your saved cars
          </SemiBold>
          
          { confusedExternalAccount.Account.Vehicles.map((res) => {
            return(
              <SummaryWrapper maxWidth={'31%'} display={'inline-block'} padding={'32px 24px 24px 24px'} marginRight={'1.5%'} key={res.Id}>
                     <p>
                       Registration:{' '}
                       <strong>
                         { res.RegistrationNumber }
                       </strong>
                       
                       <br />
                       Make:{' '}
                       <strong>
                         { res.Make }
                       </strong>
                     </p>
                     <Button 
                       id={'TeslaQuoteNextButton'}
                       type={'button'} 
                       onClick={() => handleStartQuote({
                        Registration: res.RegistrationNumber,
                        ShowVehicleBuilder: false,
                        ConfusedVehicleSelected: res.Id
                       })}
                       marginBottom={'5%'}
                       disabled={!appStartupComplete || searchingRegNumber}
                       includeArrow={appStartupComplete || searchingRegNumber}
                       fontSize={'18px'}
                     >
                      {
                          !appStartupComplete || searchingRegNumber ? 
                            <>
                              Loading...&nbsp;<Spinner />
                            </>
                         :
                            <>
                              Select Car
                            </>
                        }
                     </Button>
                 </SummaryWrapper>
            )
           }
          )}
        </>
        )
      }
      </section>
    </ConfusedWrapper>
  )
}

function mapStateToProps(state) {
  return {
    riskData: state.riskData,
    vehicle: state.vehicle,
    appStartupComplete: state.appStartupComplete,
    confusedExternalAccount: state.confusedExternalAccount,
    features: state.features || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        isLoading,
        updateStep,
        createUnkownVehicle,
        requestConfusedExternalAccount,
        requestVehicle
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
