import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { H1 as Common_H1 } from '../common/H1'
import { H2 as Common_H2 } from '../common/H2'
import { H3 as Common_H3 } from '../common/H3'
import { A as Common_A } from '../common/A'
import { P as Common_P } from '../common/P'

export const H1 = styled(Common_H1)`
  font-family: Poppins, Helvetica, Arial, sans-serif !important;
  font-size: 32px;
  max-width: none;
  color: #1f1f1f;
  display: block;
  font-weight: 700;
  margin: 0.67em 0;
  text-align: left;
`

export const H2 = styled(Common_H2)`
  font-family: Poppins, Helvetica, Arial, sans-serif !important;
  font-size: 24px;
  max-width: none;
  display: block;
  font-weight: 400;
  margin: 0.83em 0;
  text-align: left;
`

export const H3 = styled(Common_H3)`
  font-family: Poppins, Helvetica, Arial, sans-serif !important;
  font-size: 18px;
  max-width: none;
  color: #1f1f1f;
  display: block;
  font-weight: ${(props) => (props.bold ? '600' : '400')};
  text-align: left;
`

export const P = styled(Common_P)`
  font-family: Poppins, Helvetica, Arial, sans-serif; !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: #1f1f1f;
  display: block;
  text-size-adjust: 100%;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export const A = styled(Common_A)`
  font-family: Poppins, Helvetica, Arial, sans-serif; !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  display: inline-block;
  text-size-adjust: 100%;
  text-decoration: underline;
  border-bottom: none;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  &:hover {
    text-decoration: underline;
  }
`

export const Italic = styled(P)`
  font-style: italic;
`

export const OL_D = styled.ol`
  list-style-type: decimal;
`

export const OL_A = styled.ol`
  list-style-type: lower-alpha;
`

export const UL_C = styled.ul`
  list-style-type: circle;
`

export const UL_N = styled.ul`
  list-style-type: none;
`

export const LI = styled.li`
  font-family: Poppins, Helvetica, Arial, sans-serif; !important;
  font-size: 18px;
  color: #1f1f1f;
  position: relative;
`

export const Centered_H1 = styled(H1)`
  text-align: center;
  font-weight: bolder;
`

export const Centered_H3 = styled(H3)`
  text-align: center;
  font-weight: bolder;
`

export const Blue = styled.span`
  color: #1f1f1f;
`
export const StaticPageHeader = styled.span`
  color: ${(props) => props.theme.staticHeaderColour};
`

export const CallUsNumber = styled.span`
  color: ${(props) => props.theme.callUsNumberColour};
`

export const Green = styled.span`
  color: #a4c733;
`

export const Icon = styled(FontAwesomeIcon)`
  display: inline-block;
  position: absolute;
  left: -1.5em;
  top: 0.2em;
`

export const TermsAndConditionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`

export const TermsAndConditionsHeader = styled.div`
  flex: 0 0 75%;
  display: grid;
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
  }
`

export const TermsAndConditionsDownload = styled.div`
    flex:0 0 25%;
    display:flex;
    justify-content:flex-end;
    align-items:flex-start;
    text-decoration: none;
    margin-top:2rem;
    margin-bottom:0rem;
    box-sizing: border-box;
    a{
        display: flex;
        align-items: center;
        text-decoration: none;
        background-color: transparent;
        color: #58AAE0
        font-size: 16px;
        line-height: 19px;
    }
    a i {
        font-size: 36px;
        margin-left: 0.5rem;
    }
    @media screen and (max-width:767px){
        flex:0 0 100%;
        justify-content:start;
        margin-top:0rem;
        margin-bottom:1rem;
    }
`
export const SemiBold = styled.div`
  font-weight: 600;
  font-size: 22px;
`